import React, { Component } from 'react';
import {Button , OutlinedInput,Typography ,Avatar, TextField,Box,IconButton,Container,FormControl,
  InputLabel,Select,MenuItem,DialogContent,DialogActions,Dialog,DialogTitle,DialogContentText}  from '@material-ui/core'


import UserContext from '../ContextManage/UserContext'
import InputAdornment from '@material-ui/core/InputAdornment';


import * as ApiFunctions from '../../util/apifunction'

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


class SignUpPage extends Component {
    static contextType =UserContext;


    constructor(props){
        super(props);
        this.state={
          fields: {},
          errors: {},    
          ID:null,
                Email:null,
                Role:null,
                Token:null,
                isloggedin:false,
                showpassword:false,
                isError:true,
                Status:null,
                otpverificationdialog:false,
                OTPSESSIONID:null,
                singupbutton:false
               

        }
    }



    async componentDidMount(){
     
        const UserContextData = this.context
        await this.setState({
          ID:UserContextData.state.ID,
          Email:UserContextData.state.Email,
          isloggedin:UserContextData.state.loggedin,
          Token:UserContextData.state.Token,
          Role:UserContextData.state.Role
         })
   
       }
     
    




        
handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
  
    // title
    if(!fields["Full_Name"]){
      formIsValid = false;
      errors["Full_Name"] = "Please Enter Your Full Name";
    }

    if(typeof fields["Full_Name"] !== "undefined"){
      if(!fields["Full_Name"].match(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/)){
        formIsValid = false;
        errors["Full_Name"] = "Enter A Valid Name";
      }      	
    }




    if(!fields["gender"]){
      formIsValid = false;
      errors["gender"] = "Select Your Gender";
    }


  //Email
  if(!fields["email"]){
    formIsValid = false;
    errors["email"] = "Email Cannot be empty";
  }

  if(typeof fields["email"] !== "undefined"){
    let lastAtPos = fields["email"].lastIndexOf('@');
    let lastDotPos = fields["email"].lastIndexOf('.');

    if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
      formIsValid = false;
      errors["email"] = "Email is not valid";
    }
  }




  //password
  if(!fields["password"]){
    formIsValid = false;
    errors["password"] = "Name Cannot be empty";
  }

  if(typeof fields["password"] !== "undefined"){
    if(!fields["password"].match(/^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$/)){
      formIsValid = false;
      errors["password"] = "At least eight characters, at least one number and both lower and uppercase letters and special characters";
    }      	
  }







      if(!fields["phoneno"]){
        formIsValid = false;
        errors["phoneno"] = "Valid Phone Number is Requires";
      }

      
      if(!fields["BD"]){
        formIsValid = false;
        errors["BD"] = "Date of Birth Cant be Empty";
      }



      if(typeof fields["phoneno"] !== "undefined"){
        if(!fields["phoneno"].match(/^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/)){
          formIsValid = false;
          errors["phoneno"] = "Enter A Valid Phone Number";
        }      	
      }
    
    this.setState({errors: errors});
    return formIsValid;
  }

  submituserdata(e){
    e.preventDefault();
    
  if(this.handleValidation()){
         this.handleotp()
        
    }else{
      alert("Please Fill The Form")
   }
  
  }

   
  handleChange(field, e){    		
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
  
  }




  

  async handleotp(){

    const header={
      'Content-Type': 'application/x-www-form-urlencoded'
     };
     
     try{

      //new api endpoint
      //https://2factor.in/API/V1/API_KEY/VOICE/PHONE_NUMBER/OTP_VALUE
      const Data = await ApiFunctions.GetApiRequest(`https://2factor.in/API/V1/0424112e-6f76-11eb-a9bc-0200cd936042
      /VOICE/${this.state.fields["phoneno"]}/AUTOGEN`,header)

    if(Data.Status==="Success"){
      alert("OTP SEND SCESSFULLY")
      this.setState({otpverificationdialog:true,OTPSESSIONID:Data.Details})
  
    }
  
    }catch(err){
      alert("Can't Send OTP! Try Again After Sometime")
      }
    }



    verifyotp2(){


      console.log('new OTP verification')
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

fetch(`
      https://2factor.in/API/V1/0424112e-6f76-11eb-a9bc-0200cd936042/VOICE/VERIFY/${this.state.OTPSESSIONID}/${this.state.fields["OTP"]}`, requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
    }




  async verifyotp(){


    this.verifyotp2();

  
    
     try{

      //Additionally, to verify the voice API endpoint, please refer below Verify Endpoint to verify the OTP sent -
      //https://2factor.in/API/V1/api_key/VOICE/VERIFY/otp_session_id/otp_entered_by_user
      const Data = await ApiFunctions.GetApiRequest(`
      https://2factor.in/API/V1/0424112e-6f76-11eb-a9bc-0200cd936042/VOICE/VERIFY/${this.state.OTPSESSIONID}/${this.state.fields["OTP"]}`)

      if(Data.Status==="Success"){
          this.setState({otpverificationdialog:false})
          this.signup()
      }
  
    }catch(err){
      this.setState({isError:true})
        console.log(err);

      }
    }


   









  async signup(){
    const header={
      'Content-Type': 'application/json'
     };
     const data={
      fullname:this.state.fields["Full_Name"], 
      email:this.state.fields["email"],
      phno:this.state.fields["phoneno"],
      password:this.state.fields["password"],
      gender:this.state.fields["gender"],
      DOB:this.state.fields["BD"]
     
     }
     try{
      const Data = await ApiFunctions.PostApiData(`/user/signup`,header,data)
       
    
     if(Data.Status===200)
     {
      this.setState({isError:false,status:200})
      alert("singup success!")
       window.location.href="/login"


     }
     if( Data.statuscode===409){
      this.setState({isError:true,status:409})
      alert("Please Use Another Email or Phone Number!")
      setTimeout(function(){ window.location.href="/register" }, 1000);
    }
    if( Data.statuscode===424){
      this.setState({isError:true,status:424})
      alert("Something Went Wrong Try Aagain After Sometime!")
      setTimeout(function(){ window.location.href="/register" }, 1000);
    }
  
    }catch(err){
      alert("INTERNAL SERVER ERROR! TRY AFTER SOMETIME")
      this.redirect(1000,'/register')
    }

}


redirect=(time,link)=>{
  setTimeout(function(){ window.location.href=`${link}` }, time);
}
  


    render() {
        return (
            <Container component="main" maxWidth="xs">
            <div style={styles.root}>
         
               <Avatar style={styles.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
              <Typography component="h1" variant="h5" align="center" gutterBottom>
                    Welcome to Sunshubh Loans
            </Typography>
              <form noValidate style={{width:'100%',marginTop:24}}  onSubmit= {this.submituserdata.bind(this)}  >
            
              
              
              <TextField
                      autoComplete="name"  name="Full_Name"
                      variant="outlined" required fullWidth
                      ref="Full_Name" id="Full_Name"
                      label="Full Name " autoFocus
                      onChange={this.handleChange.bind(this, "Full_Name")} 
                      value={this.state.fields["Full_Name"] || ''}
                  />
                     <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["Full_Name"]}</Typography>
               
                    {/* emails */}


                    <TextField
                      autoComplete="email"  name="email"
                      variant="outlined" required fullWidth
                      ref="email" id="email"
                      label="Email" 
                      onChange={this.handleChange.bind(this, "email")} 
                      value={this.state.fields["email"] || ''}
                  />
                     <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["email"]}</Typography>
               
                    

                    
                     <FormControl variant="outlined" style={{width:"100%"}}>
                                      <InputLabel id="demo-simple-select-outlined-label">Select Gender</InputLabel>
                                      <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                      
                                        label="Gender"
                                        onChange={this.handleChange.bind(this, "gender")} 
                                           value={this.state.fields["gender"] || ''}
                                      >
                                      
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Female">Female</MenuItem>
                                     
                                      </Select>
                                    </FormControl>


                                    <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["gender"]}</Typography>



                                    <FormControl style={{width:"100%"}} variant="filled">

                                                                      

                                      <TextField
                                                  id="date"
                                                  label="Birthday"
                                                  type="date"
                                                  variant="outlined"
                                                  defaultValue="2017-05-24"
                                                  value={this.state.fields["BD"] || ''}
                                                  onChange={this.handleChange.bind(this, "BD")}
                                                  InputLabelProps={{
                                                  shrink: true,
                                                  }}
                                              />

                                        </FormControl>
                                        <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["BD"]}</Typography>





                     <TextField
                      autoComplete="phone"  name="Phone Number"
                      variant="outlined" 
                      required 
                      fullWidth
                   
                      type='number'
                      ref="phoneno" id="phoneno"
                      label="Phone Number" autoFocus
                      onChange={this.handleChange.bind(this, "phoneno")} 
                      value={this.state.fields["phoneno"] || ''}
           />
                     <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["phoneno"]}</Typography>
               
                    





     <FormControl variant="outlined" style={{width:'100%'}} >
          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
          <OutlinedInput
          fullWidth
            id="standard-adornment-password"
            type={this.state.showpassword ? 'text' : 'password'}
            onChange={this.handleChange.bind(this, "password")} 
            value={this.state.fields["password"] || ''}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>{
                   this.setState({showpassword:!this.state.showpassword})
                     
                  }}
                  onMouseDown={(event)=>{
                      event.preventDefault()
                  }}
                >
                  {this.state.showpassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
<Typography variant="caption" color="initial">Example:- Brijesh@123</Typography>
             </FormControl>
             <Typography variant="body2" align="left" color="error" style={{fontWeight:700,marginBottom:'20px'}}>{this.state.errors["password"]}</Typography>

               
               
    <Dialog open={this.state.otpverificationdialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Verify OTP</DialogTitle>
        <DialogContent>
          <DialogContentText>
          To Create New Account ,Enter the OTP Sent on your Phone
          </DialogContentText>
        
        
          <TextField
            
            margin="dense"
            id="otp"
            label="OTP"
            type="number"
            fullWidth
            onChange={this.handleChange.bind(this, "OTP")} 
            value={this.state.fields["OTP"] || ''}
          />


        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.verifyotp()} color="primary">
            verify
          </Button>
        </DialogActions>
      </Dialog>
               
               





              {/* ending of module 5 */}



      <Box m={3} align="center">    </Box>
              <Button
              fullWidth
   
              align="center"
                  type="submit" 
                  
                  variant="contained" color="primary" value="submit" id="submit">
                  Register
                </Button>
            
              </form>
              <br/>
          
             </div>


             </Container>
            
        );
    }
}

export default SignUpPage;



const styles={
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop:100
    },
    avatar:{
        margin: 8,
        backgroundColor:"skublue"
    }
  }